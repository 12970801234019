<template>
  <div>
    <div class="breadCurmb mt-3">
      <label>
        <router-link to="/">{{ $t("Home") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $t("ImportSubscribes") }}</label>
    </div>

    <v-toolbar-title class="mt-3">استيراد مشتركين</v-toolbar-title>

    <v-container fluid>
      <v-row>
        <v-col md="3">
          <div
            class="form-group"
            :class="{
              'has-error': errors.has('addEditValidation.excelFile'),
            }"
          >
            <div class="mt-2">
              <label class="control-label">ملف الاكسل</label>
            </div>
            <v-file-input
              class="d-block mt-2"
              name="excelFile"
              data-vv-scope="addEditValidation"
              v-validate="'required'"
              :data-vv-as="$t('ExcelFile')"
              hide-details
              dense
              outlined
              v-model="addEditObj.file"
              clearable
              prepend-icon=""
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              :placeholder="$t('Select Excel File')"
            ></v-file-input>
            <div
              class="help-block"
              v-if="errors.has('addEditValidation.excelFile')"
            >
              {{ errors.first("addEditValidation.excelFile") }}
            </div>
          </div>
        </v-col>

         <v-col md="3" class="mt-2">
          <div
            class="form-group"
            :class="{
              'has-error': errors.has('addEditValidation.date'),
            }"
          >
            <label class="control-label">{{ $t("Date") }}</label>
            <div>
              <el-date-picker
                type="date"
                name="date"
                class="form-control mt-2"
                style="width: 100%"
                v-validate="'required'"
                data-vv-scope="addEditValidation"
                :data-vv-as="$t('Date')"
                v-model="addEditObj.date"
                @change="changeDate()"
              ></el-date-picker>
            </div>
            <div
              class="help-block"
              v-if="errors.has('addEditValidation.date')"
            >
              {{ errors.first("addEditValidation.date") }}
            </div>
          </div>
        </v-col>

        <v-col md="3" class="mt-9">
          <el-button @click="importFile" type="success"
            >استيراد
          </el-button>
          <el-button v-if="reportFile" style="margin-right: 10px" @click="fetchReportFile" type="success">عرض التقارير</el-button>

        </v-col>
      </v-row>
       <v-row v-loading="loading" class="mt-5"></v-row>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {
      loading: false,
      reportFile :null,
      addEditObj: {
        file: null,
        date: null,
      }
    };
  },
  methods: {

    changeDate(){
      this.reportFile = null;
    },
    fetchReportFile() {
      // Simulated backend request
      this.loading = true;
      setTimeout(() => {
        this.loading = false;

        // Route to the link when reportFile is received
        window.open(this.reportFile, '_blank');
      }, 2000); // Simulating delay for demonstration
    },
    importFile() {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");  

        let send = {
          date: moment(this.addEditObj.date).format("YYYY-MM-DD"),
          file: this.addEditObj.file
        };

        this.loading= true;
        this.$store.dispatch(`importSubscribes/importExcel`, send).then(res => {
          this.reportFile = res.path
          this.notifySuccess("ImportExcel", res);
          this.loading= false;
        }).catch(error => {
           this.loading= false;
          if(error.response.status == 500)
          return this.notifyCustomError("Error", "PleaseReviewExcelSheetData");
        }).finally(() => {
           this.loading= false;
        })
      });
    },
  },
};
</script>
